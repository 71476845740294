<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Transaction Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      isReportTableBusy: false,
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,
      branches: [],
      branchID: "",
      status: "",
      statusArr: ["Success", "Failure", "Pending"],
      daterange: [],
      tableData: [],
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      title: "Transactions",
      items: [
        {
          text: "View",
        },
        {
          text: "Transactions",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        // {
        //   key: "Action",

        //   sortable: false,
        // },
        {
          key: "OrderID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "restaurantName",
          sortable: true,
        },
        {
          key: "userName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "transactionID",
          sortable: true,
        },
        {
          key: "transactionAmount",
          class: "my-class",
          sortable: true,
          width: "20px",
          tdClass: "align-right",
        },
        {
          key: "paymentMode",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getAllCountry();
    this.restOrderTransactions();
    // this.getRestaurantBrands();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "OrderTransaction",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "order-transaction.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.countryArr = response.data.data;
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.cityArr = response.data.data;
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            }
            // if (this.$storageData.profile.empTypeID == 1) {
            //   // super admin
            //   // this.getRestaurantBrands();
            //   this.getAllCompanies();
            // }
            // if (this.$storageData.profile.empTypeID == 11) {
            //   // food company login
            //   this.getAllCompanies();
            //   this.getRestaurantBrandsByCompanyID();
            // } else {
            //   this.readRestaurantBranchData();
            // }
          });
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : this.$storageData.profile.city,
          empID: this.$storageData.profile.pid,
        })
        .then((result) => {
          this.brandArr = result.data.data;
        });
    },
    readRestaurantBranchData() {
      this.branches = [];
      this.branchID = "";
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID: this.brandData.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : "",
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          this.branches = response.data.data;
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
        });
    },

    // changes done by Mohammed on 13-12-2021
    restOrderTransactions() {
      this.isReportTableBusy = true;
      var restID =
        this.brandData != "" ? this.brandData.restID : this.$storageData.profile.restID;
      var restBranchID = this.restBranchID;
      this.axios
        .post(this.$loggedRole+"/orderTransactions", {
          restID: restID,
          restBranchID: restBranchID,
          daterange: this.daterange,
          status: this.status,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.city ? this.city.city :"",
        })
        .then((response) => {
          this.isReportTableBusy = false;
          //Then injecting the result to datatable parameters.
          this.transactionAmount = response.data.transactionAmount;
          this.tableData = response.data.data;
          //console.log((response));
        });
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.restOrderTransactions();
    },
    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.branchID = "";
      this.restBranchID = "";
      this.status = "";
      this.country = "";
      this.city = "";
      this.cityArr= [];
      this.restOrderTransactions();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                track-by="country"
                label="country"
                @input="getAllCities()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Cities List-->
          <div class="col-md-2" style="width: 12%">
            <label>City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                track-by="city"
                label="city"
                @input="getRestaurantBrands()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Brand List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.login_type == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              track-by="restID"
              label="name"
              @input="readRestaurantBranchData()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Branch List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.login_type == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              track-by="restBranchID"
              label="branchName"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Branch List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.login_type == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Status </label>
            <multiselect v-model="status" :options="statusArr"></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width: 20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              range
              append-to-body
              lang="en"
              value-type="format"
              confirm
            ></date-picker>
          </div>
          <!-- Date range END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto; margin-top: 23px">
            <button class="btn btn-themeOrange" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%; margin-top: 23px">
            <button class="btn btn-themeBrown" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                  <!-- <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="isReportTableBusy"
                foot-clone
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(Action)>
                  <i
                    class="uil uil-edit-alt"
                    title="Edit"
                    style="font-size: 19px"
                  ></i>
                  <i
                    class="mdi mdi-delete"
                    title="Delete"
                    style="font-size: 19px"
                  ></i>
                </template>
                <template v-slot:cell(status)="data" style="width: 10px">
                  <span
                    v-if="data.item.status == 'success'"
                    style="color: green"
                  >
                    {{ data.item.status }}</span
                  >
                  <span v-else style="color: red"> {{ data.item.status }}</span>
                </template>
                <template v-slot:cell(transactionID)="data">
                  <span :title="data.item.transactionID">{{
                    data.item.transactionID
                  }}</span>
                </template>
                <template
                  v-slot:cell(transactionAmount)="data"
                  style="width: 10px"
                >
                  <span v-if="data.item.transactionAmount">
                    ₹{{ data.item.transactionAmount }}</span
                  >
                </template>
                <template v-slot:foot(OrderID)>
                  <span>Total:</span>
                </template>
                <template v-slot:foot(restaurantName)>
                  <span></span>
                </template>
                <template v-slot:foot(userName)>
                  <span></span>
                </template>
                  <template v-slot:foot(transactionID)>
                  <span></span>
                </template>
                  <template v-slot:foot(transactionAmount)>
                  <span>{{ transactionAmount ? $func.formatPrice(transactionAmount) : $func.formatPrice(0) }}</span>
                </template>
                <template v-slot:foot(paymentMode)>
                  <span></span>
                </template>
                <template v-slot:foot(status)>
                  <span></span>
                </template>
                <template v-slot:foot(created)>
                  <span></span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>
